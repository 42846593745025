<template>
    <v-container>
        <v-row>
            <v-col cols="6" offset="3">
                <v-card>

                    <v-form @submit.prevent="passwordRequest" ref="requestForm">
                        <v-card-title>{{ $t('password_request') }}</v-card-title>

                        <v-card-text>
                            <v-text-field
                                    id="emailField"
                                    :label="$t('email')"
                                    type="email"
                                    v-model="email"
                                    :rules="emailRules"
                                    required
                            />

                            <p class="mt-4">
                                {{ $t('password_request_text') }}
                            </p>

                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-btn id="cancelButton" to="/login" color="error">
                                <v-icon dark left>mdi-cancel</v-icon>
                                {{ $t('cancel') }}
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn id="submitButton" type="submit" color="info">
                                <v-icon dark left>mdi-send</v-icon>
                                {{ $t('request_link') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>

                </v-card>
            </v-col>
        </v-row>

        <v-snackbar id="snackbar" :color=snackbarColor :top=snackbarTop v-model="snackbar">
            {{ snackbarMessage }}

            <v-btn text @click="snackbar=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import userService from '@/services/UserService.js'

    export default {
        name: 'PasswordRequest',

        data() {
            return {
                email: '',

                snackbar: false,
                snackbarTop: true,
                snackbarColor: '',
                snackbarMessage: '',

                emailRules: [
                    value => !!value || this.$t('required'),
                    value => value.includes('@') || this.$t('email_invalid'),
                ]
            }
        },

        methods: {
            passwordRequest() {
                if (this.$refs.requestForm.validate()) {
                    userService.passwordRequest(
                        {
                            email: this.email,
                        }
                    )
                        .then(() => {
                            this.snackbar = true
                            this.snackbarColor = "success"
                            this.snackbarMessage = this.$t('link_sent')
                        })
                        .catch(() => {
                            this.snackbar = true
                            this.snackbarColor = "error"
                            this.snackbarMessage = this.$t('unknown_account')
                        })
                }
            },
        },
    };
</script>
